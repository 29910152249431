import React, { useState, useEffect } from "react";
import { SAY_TYPE, AGENT_TYPE, END_CHAT_TYPE, RESET_TYPE } from "../config";

const changeableTypes = [SAY_TYPE, AGENT_TYPE, END_CHAT_TYPE, RESET_TYPE];

const NodeTypeSwitcher = ({ node, onUpdate }) => {
    
    const [type, setType] = useState(node.type);

    useEffect(() => {
        setType(node.type);
    }, [node]);

    if (!changeableTypes.includes(node.type)) {
        return null;
    }

    const submitUpdate = () => {
        if (type === node.type) return;
        onUpdate({ ...node, type });
    }

    return (
        <div className="editor-section type-switcher">
            <h4>Type</h4>
            <select value={type} onChange={ev => setType(ev.target.value)}>
                {changeableTypes.map(t => <option key={t} value={t}>{t}</option>)}
            </select>
            &nbsp;
            <button onClick={submitUpdate} disabled={type === node.type}>
                Change
            </button>          
        </div>
    );
};

export default NodeTypeSwitcher;
