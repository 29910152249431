const scheduleActiveNode = () => {
    const node = {
        Id: window.crypto.randomUUID(),
        Name: "Is Schedule Active Node",
        Edges: [],
        Properties: {},
        Type: "IsScheduleActiveNode",
    };

    const builder = {
        build,
        withOutputExpression,
    };

    function withOutputExpression(expr) {
        node.Properties.Output = {
            Type: "SetContextPropertyAccessor",
            Value: {
                Expression: expr,
            },
        };

        return builder;
    };

    function build() {
        return node;
    };

    return builder;
};

export default scheduleActiveNode;
