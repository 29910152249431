import React, { useState, useEffect } from "react";
import "./index.css";

function EdgeEditor({ edge, onUpdate }) {

    const [handleText, setHandleText] = useState(edge.handleText);

    useEffect(() => {
        setHandleText(edge.handleText);        
    }, [edge]);
    
    const submitUpdate = () => {
        onUpdate({ ...edge, handleText });
    };

    return (
        <div className="editor-section edge-editor">
            <h4>Update Quick Reply Option</h4>
            <textarea 
                className="edge-text"
                value={handleText}
                onChange={e => setHandleText(e.target.value)}
            />
            <button onClick={submitUpdate}>
                Update Edge
            </button>
        </div>
    );
}

export default EdgeEditor;
