const queueInteractionNode = () => {
    const node = {
        Id: window.crypto.randomUUID(),
        Name: "Queue Interaction To Be Assigned",
        Edges: [],
        Properties: {},
        Type: "QueueInteractionToBeAssignedNode",
    };

    const builder = {
        build,
    };

    function build() {
        return node;
    };

    return builder;
};

export default queueInteractionNode;
