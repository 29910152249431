const leaveMessageNode = () => {
    const node = {
        Id: window.crypto.randomUUID(),
        Name: "Fallback: Leave a Message",
        Edges: [],
        Properties: {},
        Type: "LeaveAMessageNode",
    };

    const builder = {
        build,
    };

    function build() {
        return node;
    };

    return builder;
};

export default leaveMessageNode;
