import React, { useState } from "react";
import "./index.css";

function ImportExport({ graph, onImport }) {

    const [show, setShow] = useState(false);
    const [json, setJson] = useState("");

    const toggleImport = () => {
        if (!show) {
            setJson("");
        }
        else {
            const newGraph = JSON.parse(json);
            onImport(newGraph);
        }

        setShow(!show);
    };

    const toggleExport = () => {
        if (!show) {
            setJson(JSON.stringify(graph));
        }

        setShow(!show);
    };

    return (
        <div className="editor-section import-export">
            <button onClick={toggleImport}>Import</button>
            <button onClick={toggleExport}>Export</button>
            {show && <button onClick={() => setShow(false)}>Cancel</button>}
            {show && <textarea 
                className="import-export-text" 
                onChange={ev => setJson(ev.target.value)} 
                value={json}
                autoFocus
                onFocus={ev => ev.target.select()}
            />
            }
        </div>
    );
}

export default ImportExport
