const autoresponderNode = (input) => {
    const node = {
        Id: window.crypto.randomUUID(),
        Name: "Autoresponder Node",
        Edges: [],
        Properties: {
            Attachments: [],
            QuickReplies: []
        },
        ...input,
        Type: "AutoresponderNode",
    };

    const builder = {
        build,
        withContent,
        withQuickReply,
        fromGraphNode,
    };

    function withContent(text) {
        node.Properties.Content = text;
        return builder;
    };

    function withQuickReply(label) {
        node.Properties.QuickReplies.push({
            Label: label,
            Description: null,
            MetaData: null,
        });

        return builder;
    };

    function fromGraphNode(graph, graphNode) {
        withContent(graphNode.message);
        graph.edges
            .filter(e => e.source === graphNode.id)
            .forEach(e => {
                withQuickReply(e.handleText);
            });

        return builder;
    };

    function build() {
        return node;
    };

    return builder;
};

export default autoresponderNode;
