import decisionNodeCondition from "./decisionNodeCondition";

const decisionNode = (input) => {
    const node = {
        Id: window.crypto.randomUUID(),
        Name: "Decision Node",
        Edges: [],
        Properties: {
            FailConnector: "Else Connection",
            Conditions: [],
        },
        ...input,
        Type: "IfElseDecisionNode",
    };

    const builder = {
        build,
        withCondition,
    };

    function withCondition(fn) {
        const conditionBuilder = decisionNodeCondition({});
        fn(conditionBuilder);
        node.Properties.Conditions.push(conditionBuilder.build());
        return builder;
    };

    function build() {
        return node;
    };

    return builder;
};

export default decisionNode;
