const availabilityCheckNode = (input) => {
    const node = {
        Id: window.crypto.randomUUID(),
        Name: "Has Availability Node",
        Edges: [],
        Properties: {},
        ...input,
        Type: "HasAvailabilityNode",
    };

    const builder = {
        build,
        withQueueId,
        withOutput,
    };

    function withQueueId(queueId) {
        node.Properties.QueueId = queueId;
        return builder;
    };

    function withOutput(expr) {
        node.Properties.Output = {
            Type: "SetContextPropertyAccessor",
            Value: {
                Expression: expr,
            },
        };

        return builder;
    }

    function build() {
        return node;
    };

    return builder;
};

export default availabilityCheckNode;
