const startNode = () => {
    const node = {
        Id: window.crypto.randomUUID(),
        Name: "Start Node",
        Type: "StartNode",
        Edges: [],
        Properties: {}
    };
    
    const build = () => node;

    return {
        build,
    };
};

export default startNode;
