import React from "react";

export const NODE_KEY = "id"; // Key used to identify nodes

// Nodes
export const START_TYPE = "startNode";
export const SAY_TYPE = "sayNode";
export const AGENT_TYPE = "agentNode";
export const END_CHAT_TYPE = "endChatNode";
export const RESET_TYPE = "resetNode";
export const nodeTypes = [SAY_TYPE, START_TYPE, AGENT_TYPE, END_CHAT_TYPE, RESET_TYPE];

const StartNodeShape = (
    <symbol viewBox="0 0 150 100" width="150" height="100" id={START_TYPE}>
        <rect x="0" y="0" rx="2" ry="2" width="150" height="100" />
    </symbol>
);

const AgentNodeShape = (
    <symbol viewBox="0 0 150 100" width="150" height="100" id={AGENT_TYPE}>
        <rect x="0" y="0" rx="2" ry="2" width="150" height="100" />
    </symbol>
);

const SayNodeShape = (
    <symbol viewBox="0 0 150 100" width="150" height="100" id={SAY_TYPE}>
        <rect x="0" y="0" rx="2" ry="2" width="150" height="100" />
    </symbol>
);

const EndChatNodeShape = (
    <symbol viewBox="0 0 150 100" width="150" height="100" id={END_CHAT_TYPE}>
        <rect x="0" y="0" rx="2" ry="2" width="150" height="100" />
    </symbol>
);

const ResetNodeShape = (
    <symbol viewBox="0 0 150 100" width="150" height="100" id={RESET_TYPE}>
        <rect x="0" y="0" rx="2" ry="2" width="150" height="100" />
    </symbol>
);

// Edges
export const REPLY_EDGE_TYPE = "replyEdge";
export const edgeTypes = [REPLY_EDGE_TYPE];

const ReplyEdgeShape = (
    <symbol viewBox="0 0 50 50" id={REPLY_EDGE_TYPE}>
    </symbol>
);

export default {
    EdgeTypes: {
        emptyEdge: {
            shape: ReplyEdgeShape,
            shapeId: `#${REPLY_EDGE_TYPE}`,
        }
    },
    NodeTypes: {
        [START_TYPE]: {
            shape: StartNodeShape,
            shapeId: `#${START_TYPE}`,
        },
        [SAY_TYPE]: {
            shape: SayNodeShape,
            shapeId: `#${SAY_TYPE}`,
        },
        [AGENT_TYPE]: {
            shape: AgentNodeShape,
            shapeId: `#${AGENT_TYPE}`,
        },
        [END_CHAT_TYPE]: {
            shape: EndChatNodeShape,
            shapeId: `#${END_CHAT_TYPE}`,
        },
        [RESET_TYPE]: {
            shape: ResetNodeShape,
            shapeId: `#${RESET_TYPE}`,
        },
    },
    NodeSubtypes: {},
};
