import React, { useState } from "react";
import generator from "./generator";
import "./index.css";

const WorkflowGenerator = ({ graph }) => {

    const [generated, setGenerated] = useState(null);

    const generate = () => {
        const workflow = generator(graph);
        console.log(workflow);
        setGenerated(JSON.stringify(workflow));
    };

    return (
        <div className="editor-section workflow-generator">
            <button onClick={generate}>Generate Workflow</button>
            {!!generated && <button onClick={() => setGenerated(null)}>Close</button>}
            {!!generated && <textarea
                className="workflow-export"
                value={generated}
                readOnly
                autoFocus
                onFocus={ev => ev.target.select()}
            />}
        </div>
    );
};

export default WorkflowGenerator;
