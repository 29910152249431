import decisionNodeComponent from "./decisionNodeComponent";

const decisionNodeCondition = (input) => {
    const condition = {
        Expression: "1",
        ...input,
    };

    const builder = {
        build,
        withConnector,
        withExpression,
        withComponents,
    };

    function withConnector(connector) {
        condition.Connector = connector;
        return builder;
    };

    function withExpression(expr) {
        condition.Expression = expr;
        return builder;
    };    

    function withComponents(fns) {
        condition.Components = fns.map(fn => {
            const componentBuilder = decisionNodeComponent({});
            fn(componentBuilder);
            return componentBuilder.build();
        });

        return builder;
    };

    function build() {
        return condition;
    }

    return builder;
};

export default decisionNodeCondition;
