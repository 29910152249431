const closeConversationNode = (input) => {
    const node = {
        Id: window.crypto.randomUUID(),
        Name: "Close Conversation Node",
        Edges: [],
        Properties: {},
        ...input,
        Type: "CloseConversationNode",
    };

    const builder = {
        build,
    };

    function build() {
        return node;
    };

    return builder;
};

export default closeConversationNode;
