const updateNode = (input) => {
    const node = {
        Id: window.crypto.randomUUID(),
        Name: "Update Interaction Node",
        Edges: [],
        Properties: {
            Updates: [],
        },
        ...input,
        Type: "UpdateInteractionNode",
    };

    const builder = {
        build,
        withDataUpdateFromConstant,
    };

    function withDataUpdateFromConstant(dataTypeId, dataTypeName, value) {
        node.Properties.Updates.push({
            Type: "SetInteractionDynamicDataPropertyAccessor",
            Value: {                
                DataType: {
                    Id: dataTypeId,
                    Name: dataTypeName,
                    DatabaseType: "String",
                },
                Value: {
                    Type: "GetConstantPropertyAccessor",
                    Value: {
                        Value: value
                    },
                },
            },
        });

        return builder;
    }

    function build() {
        return node;
    };

    return builder;
};

export default updateNode;
