import React from "react"
import { GraphView } from "react-digraph";
import GraphConfig, { SAY_TYPE, START_TYPE, REPLY_EDGE_TYPE, AGENT_TYPE, RESET_TYPE } from "./config";
import NodeEditor from "./NodeEditor";
import EdgeEditor from "./EdgeEditor";
import ImportExport from "./ImportExport";
import NodeTypeSwitcher from "./NodeTypeSwitcher";
import WorkflowGenerator from "./WorkflowGenerator";
import "./index.css";

// NOTE: Edges must have "source" & "target" attributes
// In a more realistic use case, the graph would probably originate
// elsewhere in the App or be generated from some other state upstream of this component.
// NOTE: Dynamic Data Type names must match or the import fails
// https://dev.azure.com/gnatta/Gnatta/_git/Gnatta.Legacy.Core?path=/src/Felicitas.Cadence.Core/Workflow/Services/Validators/RuleNodeValdiators/SetPropertyAccessorValidators/SetInteractionDynamicDataPropertyAccessorValidator.cs&version=GBmaster&line=44&lineEnd=44&lineStartColumn=21&lineEndColumn=97&lineStyle=plain&_a=contents
let initial = {
    dynamicData: {
        categoryValue: "CURRENT_CATEGORY",
        categoryId: "CATEGORY_TYPE_GUID",
        categoryName: "CATEGORY TYPE NAME",
        stageId: "STAGE_TYPE_GUID",
        stageName: "STAGE TYPE NAME",
    },
    edges: [],
    nodes: [
        {
            id: "start",
            message: "INITIAL MESSAGE",
            type: START_TYPE,
            x: 500,
            y: 300
        }
    ],
};

class Graph extends React.Component {
    GraphView;
    constructor(props) {
        super(props);

        this.state = {
            graph: initial,
            layoutEngineType: undefined,
            selection: null,
            selectedNode: null,
            selectedEdge: null
        };

        this.GraphView = React.createRef();
    }

    // Helper to find the index of a given node
    // Imported from digraph examples
    getNodeIndex(searchNode) {
        return this.state.graph.nodes.findIndex(node =>
            node.id === searchNode.id
        );
    }

    // Given a nodeKey, return the corresponding node
    // Imported from digraph examples
    getViewNode(nodeKey) {
        const searchNode = {};
        searchNode.id = nodeKey;
        const i = this.getNodeIndex(searchNode);
        return this.state.graph.nodes[i];
    };

    // Imported from digraph examples
    getEdgeIndex(searchEdge) {
        return this.state.graph.edges.findIndex(edge =>
            edge.source === searchEdge.source && edge.target === searchEdge.target
        );
    };

    // Imported from digraph examples
    getViewEdge(searchEdge) {
        const i = this.getEdgeIndex(searchEdge);
        return this.state.graph.edges[i];
    };

    /*
     * Handlers/Interaction
     */

    // Called by "drag" handler, etc..
    // to sync updates from D3 with the graph
    // Imported from digraph examples
    onUpdateNode = (
        viewNode,
        selectedNodes,
        position
    ) => {
        const graph = this.state.graph;
        const i = this.getNodeIndex(viewNode);
        const overrides = {
            ...this.state.locationOverrides,
            [viewNode.id]: position,
        };

        graph.nodes[i] = viewNode;
        this.setState({ graph, locationOverrides: overrides });
    };

    // Updates the graph with a new node
    // Imported from digraph examples
    onCreateNode = (x, y) => {
        const graph = this.state.graph;

        const viewNode = {
            id: crypto.randomUUID(),
            message: "Example Response",
            type: SAY_TYPE,
            x,
            y,
        };

        graph.nodes = [...graph.nodes, viewNode];
        this.setState({ graph });
    };

    // Creates a new node between two edges
    // Imported from digraph examples
    onCreateEdge = (sourceViewNode, targetViewNode) => {
        const graph = this.state.graph;
        const viewEdge = {
            source: sourceViewNode.id,
            target: targetViewNode.id,
            handleText: "Quick Reply",
            type: REPLY_EDGE_TYPE,
        };

        // Only add the edge when the source node is not the same as the target
        if (viewEdge.source !== viewEdge.target) {
            graph.edges = [...graph.edges, viewEdge];
            this.setState({
                graph,
                selected: {
                    nodes: null,
                    edges: new Map([[`${viewEdge.source}_${viewEdge.target}`, viewEdge]]),
                },
            });
        }
    };

    // Called when deleting an object
    onDeleteSelected = (remove) => {
        const graph = this.state.graph;

        // Remove exact nodes
        const nodesToRemove = Array.from(remove.nodes || [], ([_, value]) => value);
        if (nodesToRemove.some(n => n.type === START_TYPE)) {
            alert("Can't remove start node");
            return;
        }

        const nodes = graph.nodes.filter(n => !nodesToRemove.some(r => n.id === r.id));

        // Remove edges and any connected to removed nodes
        const edgesToRemove = Array.from(remove.edges || [], ([_, value]) => value);
        const edges = graph.edges.filter(e =>
            !edgesToRemove.some(r => e.source === r.source && e.target === r.target) &&
            !nodesToRemove.some(n => e.source === n.id || e.target === n.id)
        );

        this.setState({
            graph: { ...graph, nodes, edges },
            selectedNode: null,
            selectedEdge: null,
            selection: null
        });
    };

    // Called when an edge is reattached to a different target.
    // Imported from digraph examples
    onSwapEdge = (sourceNode, targetNode, viewEdge) => {
        const graph = this.state.graph;

        const edge = { ...viewEdge };

        edge.source = sourceNode.id;
        edge.target = targetNode.id;
        graph.edges[graph.edges.indexOf(viewEdge)] = edge;

        // reassign the array reference if you want the graph to re-render a swapped edge
        graph.edges = [...graph.edges];

        this.setState({
            graph: { ...graph },
            selectedEdge: edge,
        });
    };

    // Imported from digraph examples
    handleChangeLayoutEngineType = (event) => {
        const value = event.target.value;
        const layoutEngineType = value;

        this.setState({
            layoutEngineType,
        });
    };

    replaceEdge = (updated) => {
        const i = this.getEdgeIndex(updated);
        if (i < 0) return;

        const { nodes, edges } = this.state.graph;

        this.setState({
            graph: {
                ...this.state.graph,
                nodes, 
                edges: [ ...edges.slice(0, i), updated, ...edges.slice(i + 1) ],
            },
            selectedEdge: null,
            selection: null
        });
    };

    replaceNode = (updated) => {
        const i = this.getNodeIndex(updated);
        if (i < 0) return;

        const { nodes, edges } = this.state.graph;

        this.setState({
            graph: {
                ...this.state.graph,
                nodes: [ ...nodes.slice(0, i), updated, ...nodes.slice(i + 1) ], 
                edges,
            },
            selectedNode: null,
            selection: null
        });
    };

    onSelect = (selection) => {
        const selectedNode = selection && selection.nodes
            ? this.getViewNode(selection.nodes.entries().next().value[0])
            : null;

        const selectedEdge = selection && selection.edges
            ? this.getViewEdge(selection.edges.entries().next().value[1])
            : null;

        this.setState({ selection, selectedEdge, selectedNode });
    };

    renderNodeText = (node) => {
        if (node.type === AGENT_TYPE) {
            return (
                <foreignObject x="-70" y="-45" width="140" height="90">
                    <div className="node-text-title">{AGENT_TYPE}</div>
                    <div>{node.connecting}</div>
                    <div>{node.fallback}</div>
                </foreignObject>
            );
        }

        if (node.type === RESET_TYPE) {
            return (
                <foreignObject x="-70" y="-45" width="140" height="90">
                    <div className="node-text-title">{RESET_TYPE}</div>
                </foreignObject>
            );
        }

        return (
            <foreignObject x="-70" y="-45" width="140" height="90">
                <div className="node-text-title">{node.type}</div>
                <div>{node.message}</div>
            </foreignObject>
        );
    }

    /*
     * Render
     */

    render() {
        const { selection, selectedNode, selectedEdge, graph, layoutEngineType } = this.state;
        const { NodeTypes, NodeSubtypes, EdgeTypes } = GraphConfig;
        const { dynamicData, nodes, edges } = graph;

        return (
            <>
                <div className="editor-overlay">
                    <ImportExport
                        graph={this.state.graph}
                        onImport={graph => this.setState({ graph })}
                    />
                    <WorkflowGenerator graph={this.state.graph} />
                    <div className="editor-section layout-engine">
                        <span>Layout Engine:&nbsp;</span>
                        <select
                            name="layout-engine-type"
                            onChange={this.handleChangeLayoutEngineType}
                        >
                            <option value={undefined}>None</option>
                            <option value={"SnapToGrid"}>Snap to Grid</option>
                            <option value={"VerticalTree"}>Vertical Tree</option>
                            <option value={"HorizontalTree"}>Horizontal Tree</option>
                        </select>
                    </div>
                    <div className="editor-section data-types">
                        <span>Category Value:</span>
                        <input type="text" 
                            value={dynamicData.categoryValue}
                            onChange={ev => this.setState({ graph: { ...graph, dynamicData: { ...dynamicData, categoryValue: ev.target.value } } })}
                        />
                        <span>Category Type Id:</span>
                        <input type="text" 
                            value={dynamicData.categoryId}
                            onChange={ev => this.setState({ graph: { ...graph, dynamicData: { ...dynamicData, categoryId: ev.target.value } } })}
                        />
                        <span>Category Type Name:</span>
                        <input type="text" 
                            value={dynamicData.categoryName}
                            onChange={ev => this.setState({ graph: { ...graph, dynamicData: { ...dynamicData, categoryName: ev.target.value } } })}
                        />
                        <span>Stage Type Id:</span>
                        <input type="text" 
                            value={dynamicData.stageId}
                            onChange={ev => this.setState({ graph: { ...graph, dynamicData: { ...dynamicData, stageId: ev.target.value } } })}
                        />
                        <span>Stage Type Name:</span>
                        <input type="text" 
                            value={dynamicData.stageName}
                            onChange={ev => this.setState({ graph: { ...graph, dynamicData: { ...dynamicData, stageName: ev.target.value } } })}
                        />
                    </div>
                    {selectedNode && <NodeTypeSwitcher node={selectedNode} onUpdate={this.replaceNode} />}
                    {selectedNode && <NodeEditor node={selectedNode} onUpdate={this.replaceNode} />}
                    {!selectedNode && selectedEdge && <EdgeEditor edge={selectedEdge} onUpdate={this.replaceEdge} />}
                </div>
                <div id="graph" style={{ height: "100%" }}>
                    <GraphView
                        ref={el => (this.GraphView = el)}
                        nodeKey={"id"}
                        readOnly={false}
                        allowMultiselect={false}
                        nodes={nodes}
                        edges={edges}
                        nodeTypes={NodeTypes}
                        nodeSubtypes={NodeSubtypes}
                        edgeTypes={EdgeTypes}
                        onCreateNode={this.onCreateNode}
                        onUpdateNode={this.onUpdateNode}
                        onCreateEdge={this.onCreateEdge}
                        onSwapEdge={this.onSwapEdge}
                        onDeleteSelected={this.onDeleteSelected}
                        selected={selection}
                        onSelect={this.onSelect}
                        layoutEngineType={layoutEngineType}
                        renderNodeText={this.renderNodeText}
                    />
                </div>
            </>
        );
    }
}

export default Graph;