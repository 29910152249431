import React from "react";
import { START_TYPE, SAY_TYPE, AGENT_TYPE, END_CHAT_TYPE, RESET_TYPE } from "../config";
import SayNodeEditor from "./SayNodeEditor";
import AgentNodeEditor from "./AgentNodeEditor";
import "./index.css";

const NodeEditor = ({ node, onUpdate }) => {
    if (!node) return null;

    switch (node.type) {
        case START_TYPE:
        case END_CHAT_TYPE:
        case SAY_TYPE: return <SayNodeEditor node={node} onUpdate={onUpdate} />;
        case AGENT_TYPE: return <AgentNodeEditor node={node} onUpdate={onUpdate} />;
        case RESET_TYPE:
        default: return null;
    }
}

export default NodeEditor;
