const stopNode = () => {
    const node = { 
        Id: window.crypto.randomUUID(),
        Name: "Stop Node",
        Type: "StopNode", 
        Edges: [], 
        Properties: { "StopProcessing": false }
    };
    
    const build = () => node;

    return {
        build,
    };
};

export default stopNode;
