const decisionNodeComponent = (input) => {
    const component = {
        ...input,
    };

    const builder = {
        build,
        withOperation,
        withEqualsOperation,
        withLeftContext,
        withRightContext,
        withRightConstant,
    };

    function withOperation(op) {
        component.Operation = op;
        return builder;
    };

    function withEqualsOperation() {
        return withOperation("Equals");
    };

    function withLeftContext(location) {
        component.Left = {
            Type: "GetContextPropertyAccessor",
            Value: {
                Expression: location,
            },
        };

        return builder;
    };

    function withRightContext(location) {
        component.Right = {
            Type: "GetContextPropertyAccessor",
            Value: {
                Expression: location,
            },
        };

        return builder;
    };

    function withRightConstant(value) {
        component.Right = {
            Type: "GetConstantPropertyAccessor",
            Value: {
                Value: value,
            },
        };

        return builder;
    };

    function build() {
        return component;
    };

    return builder;
};

export default decisionNodeComponent;
