import React, { useState, useEffect } from "react";

const SayNodeEditor = ({ node, onUpdate }) => {
    
    const [message, setMessage] = useState(node.message);

    useEffect(() => {
        setMessage(node.message);
    }, [node]);

    const submitUpdate = () => {
        onUpdate({ ...node, message });
    };

    return (
        <div className="editor-section say-node-editor">
            <h4>Message</h4>
            <textarea
                className="node-text"
                value={message}
                onChange={e => setMessage(e.target.value)}
            />
            <button onClick={submitUpdate}>
                Update Node
            </button>          
        </div>
    );
};

export default SayNodeEditor;
