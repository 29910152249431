import React, { useState, useEffect } from "react";

const AgentNodeEditor = ({ node, onUpdate }) => {
    
    const [fallback, setFallback] = useState(node.fallback);
    const [connecting, setConnecting] = useState(node.connecting);
    const [queueId, setQueueId] = useState(node.queueId);

    useEffect(() => {
        setFallback(node.fallback);
        setConnecting(node.connecting);
        setQueueId(node.queueId);
    }, [node]);

    const submitUpdate = () => {
        onUpdate({ ...node, fallback, connecting, queueId });
    };

    return (
        <div className="editor-section agent-node-editor">
            <h4>Fallback Message</h4>
            <textarea
                className="node-text"
                value={fallback}
                onChange={e => setFallback(e.target.value)}
            />
            <h4>Connecting Message</h4>
            <textarea
                className="node-text"
                value={connecting}
                onChange={e => setConnecting(e.target.value)}
            />
            <h4>Availability Queue (opt.)</h4>
            <input
                className="node-field"
                value={queueId}
                onChange={e => setQueueId(e.target.value)}
            />
            <button onClick={submitUpdate}>
                Update Node
            </button>
        </div>
    );
};

export default AgentNodeEditor;
