import { SAY_TYPE, START_TYPE, AGENT_TYPE, RESET_TYPE, END_CHAT_TYPE } from "../config";
import builders from "./builders";

const addEdge = (node, toNode, name) => {
    if (!Array.isArray(node.Edges)) {
        node.Edges = [];
    };
    
    node.Edges.push({
        Connector: name || "Out",
        ToNode: toNode.Id,
    });
};

const generator = (graph) => {
    // validate?

    // generate
    const startNode = builders.startNode().build();
    const stopNode = builders.stopNode().build();
    
    // Initial check to see if we're both in automation, and that the current category is relevant
    const automationDecisionNode = builders.decisionNode({ Name: "Decision: Check Category and Automation" })
        .withCondition(cnd => cnd
            .withConnector("Proceed")
            .withExpression("1 & 2")
            .withComponents([
                cmp => cmp
                    .withEqualsOperation()
                    .withLeftContext("Interaction.Automation")
                    .withRightConstant("True"),
                cmp => cmp
                    .withEqualsOperation()
                    .withLeftContext(`Interaction.Data[${graph.dynamicData.categoryId}]`)
                    .withRightConstant(graph.dynamicData.categoryValue)
            ])
        )
        .build();

    addEdge(startNode, automationDecisionNode);
    addEdge(automationDecisionNode, stopNode, "Else Connection");
        
    // Add decision node that will check a dynamic data type for the value of the message ID to determine the current stage
    const stageCheckBuilder = builders.decisionNode({ Name: "Decision: Check Current Stage" });

    // Add conditions for the stage check builder for all the sayNode types
    graph.nodes.filter(n => SAY_TYPE === n.type).forEach(n => {            
        stageCheckBuilder.withCondition(cnd => cnd
            .withConnector(`Stage ${n.id}`)
            .withComponents([
                cmp => cmp
                    .withEqualsOperation()
                    .withLeftContext(`Interaction.Data[${graph.dynamicData.stageId}]`)
                    .withRightConstant(n.id)
            ])
        );
    });
    
    const stageCheckNode = stageCheckBuilder.build();

    // Connect positive check from automation decision to stage check
    addEdge(automationDecisionNode, stageCheckNode, "Proceed");

    // Generate sections for each graph node
    const stageMap = {};

    graph.nodes.forEach(node => {
        if ([SAY_TYPE, START_TYPE].includes(node.type)) {
            const sayNode = builders.autoresponderNode({ Name: `Say: ${node.id}` })
                .fromGraphNode(graph, node)
                .build();

            const updateNode = builders.updateNode({ Name: `Update: Stage ${node.id}` })
                .withDataUpdateFromConstant(graph.dynamicData.stageId, graph.dynamicData.stageName, node.id)
                .build();

            addEdge(sayNode, updateNode);
            addEdge(updateNode, stopNode);

            stageMap[node.id] = [sayNode, updateNode];
        }
        else if (node.type === AGENT_TYPE) {            
            const shouldCheckAvailability = node.queueId && node.queueId.trim().length > 0;

            const scheduleLookupNode = builders.scheduleActiveNode()
                .withOutputExpression("var.autoActiveCheck")
                .build();

            const availabilityLookupNode = builders.availabilityCheckNode({})
                .withQueueId(node.queueId)
                .withOutput("var.autoAvailabilityCheck")
                .build();     

            const agentCheckNode = builders.decisionNode({ Name: "Decision: Should Go To Agent" })
                .withCondition(cnd => {
                    const activeCheckComponent = cmp => cmp
                        .withEqualsOperation()
                        .withLeftContext("var.autoActiveCheck")
                        .withRightConstant("True");

                    const availabilityCheckComponent = cmp => cmp
                        .withEqualsOperation()
                        .withLeftContext("var.autoAvailabilityCheck")
                        .withRightConstant("True");

                    return cnd
                        .withConnector("ShouldGoToAgent")
                        .withExpression(shouldCheckAvailability 
                            ? "1 & 2" 
                            : "1")
                        .withComponents(shouldCheckAvailability 
                            ? [activeCheckComponent, availabilityCheckComponent] 
                            : [activeCheckComponent]);
                })
                .build();

            const agentCheckNodes = shouldCheckAvailability
                ? [scheduleLookupNode, availabilityLookupNode, agentCheckNode]
                : [scheduleLookupNode, agentCheckNode];

            // Check how these nodes are going to be wired up
            if (shouldCheckAvailability) {
                addEdge(scheduleLookupNode, availabilityLookupNode);
                addEdge(availabilityLookupNode, agentCheckNode);
            }
            else {
                addEdge(scheduleLookupNode, agentCheckNode);
            }

            // Out of hours path
            const fallbackSayNode = builders.autoresponderNode({ Name: "Say: Fallback" })
                .withContent(node.fallback)
                .build();

            addEdge(agentCheckNode, fallbackSayNode, "Else Connection");

            const leaveMessageNode = builders.leaveMessageNode().build();
            addEdge(fallbackSayNode, leaveMessageNode);

            const closeConversationNode = builders.closeConversationNode({}).build();
            addEdge(leaveMessageNode, closeConversationNode);
            addEdge(closeConversationNode, stopNode);

            // In hours path
            const connectingSayNode = builders.autoresponderNode({ Name: "Say: Connecting to Agent" })
                .withContent(node.connecting)
                .build();

            addEdge(agentCheckNode, connectingSayNode, "ShouldGoToAgent");

            const queueInteractionNode = builders.queueInteractionNode().build();
            addEdge(connectingSayNode, queueInteractionNode);

            const updateNode = builders.updateNode({ Name: "Update: Queued Category and Journey" })
                .withDataUpdateFromConstant(graph.dynamicData.stageId, graph.dynamicData.stageName, "QUEUED")
                .withDataUpdateFromConstant(graph.dynamicData.categoryId, graph.dynamicData.categoryName, "QUEUED")
                .build();

            addEdge(queueInteractionNode, updateNode);
            addEdge(updateNode, stopNode);

            stageMap[node.id] = [
                ...agentCheckNodes,
                connectingSayNode, queueInteractionNode, 
                updateNode, fallbackSayNode, 
                agentCheckNode, leaveMessageNode, 
                closeConversationNode
            ];
        }
        else if (node.type === RESET_TYPE) {
            const updateNode = builders.updateNode({ Name: "Update: Reset Category and Journey" })
                .withDataUpdateFromConstant(graph.dynamicData.stageId, graph.dynamicData.stageName, "RESET")
                .withDataUpdateFromConstant(graph.dynamicData.categoryId, graph.dynamicData.categoryName, "RESET")
                .build();

            addEdge(updateNode, stopNode);

            stageMap[node.id] = [updateNode];
        }
        else if (node.type === END_CHAT_TYPE) {
            const sayNode = builders.autoresponderNode({ Name: "Say: End Chat" })
                .withContent(node.message)
                .build();
            
            const updateNode = builders.updateNode({ Name: "Update: Finish Category and Journey" })
                .withDataUpdateFromConstant(graph.dynamicData.stageId, graph.dynamicData.stageName, "FINISHED")
                .withDataUpdateFromConstant(graph.dynamicData.categoryId, graph.dynamicData.categoryName, "FINISHED")
                .build();
            
            const closeNode = builders.closeConversationNode({ Name: "Close: Finished" })
                .build();

            addEdge(sayNode, updateNode);
            addEdge(updateNode, closeNode);
            addEdge(closeNode, stopNode);

            stageMap[node.id] = [sayNode, updateNode, closeNode];
        }
    });
    
    // Connect first message up to the else connector of the stage check
    addEdge(stageCheckNode, stageMap["start"][0], "Else Connection");  

    // Generate response checks for each stage
    const responseCheckNodes = graph.nodes.filter(n => [SAY_TYPE, START_TYPE].includes(n.type)).map(n => {
        const outboundEdges = graph.edges.filter(e => e.source === n.id);

        // create response check node
        const responseCheckBuilder = builders.decisionNode({ Name: `Decision: Stage ${n.id}` });

        // add conditions for each edge
        outboundEdges.forEach(e => {
            responseCheckBuilder.withCondition(cnd => cnd
                .withConnector(`To ${e.target}`)
                .withComponents([
                    cmp => cmp
                        .withEqualsOperation()
                        .withLeftContext("Echo.Body")
                        .withRightConstant(e.handleText)
                ])
            );
        });

        const responseCheckNode = responseCheckBuilder.build();

        // Connect the stage check to the response check
        addEdge(stageCheckNode, responseCheckNode, `Stage ${n.id}`);

        // Add else connection to stop node, TODO: a default message and loop back?
        addEdge(responseCheckNode, stopNode, "Else Connection");

        // Add edges to the connected stage sections
        outboundEdges.forEach(e => {
            addEdge(responseCheckNode, stageMap[e.target][0], `To ${e.target}`);
        });

        return responseCheckNode;
    });

    return {
        Name: "Generated Quick Reply Workflow",
        Disabled: false,
        Nodes: [
            startNode,
            automationDecisionNode,
            stageCheckNode,
            ...responseCheckNodes,
            ...Object.values(stageMap).flat(),
            stopNode,
        ],
    };
};

export default generator;
